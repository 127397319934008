import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useContext,
} from 'react';
// Removed duplicate React import block
import Amplify, { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
// import storage from './storage'; // Removed storage import
import { PageLoading } from './ui';
import * as Sentry from '@sentry/node';
// import cookie from 'js-cookie' // Removed cookie import
// import { JwtPayload } from "jsonwebtoken" // Removed JwtPayload import
// import { verifyToken } from './jwt'; // Removed verifyToken import

const isServer = typeof window === 'undefined';

export const initializeAmplify = () =>
  Amplify.configure({
    Auth: {
      region: process.env.NEXT_PUBLIC_COGNITO_REGION,
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID,
      // Add explicit cookie storage configuration
      cookieStorage: {
        domain: process.env.NODE_ENV === 'production' ? 'app.postdrop.io' : 'localhost',
        path: '/',
        expires: 365, // Keep cookies for a year
        secure: process.env.NODE_ENV === 'production', // Use secure cookies in production
        sameSite: 'lax' // Recommended setting for auth cookies
      },
      // Add OAuth settings for Cognito Hosted UI
      oauth: {
        // Handle potential https:// prefix in the env var
        domain: (process.env.NEXT_PUBLIC_COGNITO_DOMAIN || '').replace(/^https?:\/\//, ''),
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'], // Added scope back as it's required downstream and now enabled in Cognito
        redirectSignIn: `${process.env.NODE_ENV === 'production' ? 'https://app.postdrop.io' : 'http://localhost:3000'}/templates`, // Redirect after successful sign-in
        redirectSignOut: `${process.env.NODE_ENV === 'production' ? 'https://app.postdrop.io' : 'http://localhost:3000'}/login`, // Redirect after sign-out
        responseType: 'code' // Use authorization code grant flow
      }
    },
    ssr: true,
  });

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  // const credential = cookie.get('id_token') // Removed credential logic

  // Simplified setUserWrap - relies only on Amplify user object
  const setUserWrap = async (user) => {
    try {
      // init users
      if (!isServer && user) {
        Sentry.setUser({
          email: user?.attributes?.email,
          id: user?.attributes?.sub,
        });
      }
      // @ts-ignore
      if (!isServer && user && window?.Intercom) {
        // @ts-ignore
        window.Intercom('boot', {
          app_id: 'dofnxiuk',
          email: user?.attributes?.email,
          user_id: user?.attributes?.sub,
          // created_at: created_at,
          // account_id: user.account_id,
          email_verified: user?.attributes?.email_verified,
          // Add fallback for name in case custom:full_name is missing
          name: user?.attributes?.['custom:full_name'] || null,
        });
      }
      // remove users
      if (!isServer && !user) {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
      // @ts-ignore
      if (!isServer && !user && window?.Intercom) {
        // @ts-ignore
        window.Intercom('shutdown');
        // @ts-ignore
        window.Intercom('boot', {
          app_id: 'dofnxiuk',
        });
      }
    } catch (_err) {
      console.error("Error in setUserWrap:", _err); // Added basic error logging
    }
    return setUser(user); // Directly set the user from Amplify
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUserWrap(user))
      .catch(() => setUserWrap(null))
      .then(() => setLoading(false));
  }, []);

  // Removed useEffect hook that relied on manual credential verification

  const login = (cognitoUser) => {
    // storage.delete('signup.email'); // Removed storage usage
    // storage.delete('signup.password'); // Removed storage usage
    setUserWrap(cognitoUser);
    return cognitoUser;
  };

  // Simplified logout - uses Amplify for all cases
  const logout = async () => {
    try {
      // @ts-ignore
      if (!isServer && window?.Intercom) {
        // @ts-ignore
        window.Intercom('shutdown');
        // @ts-ignore
        window.Intercom('boot', {
          app_id: 'dofnxiuk',
        });
      }
    } catch (error) {
      console.error("Error shutting down Intercom on logout:", error);
    }
    // Use global sign out to invalidate tokens everywhere
    return Auth.signOut({ global: true }).then((data) => {
      router.push('/login');
      setUser(null); // Clear user state immediately
      return data;
    }).catch(err => {
      console.error("Error signing out:", err);
      // Still attempt to clear state and redirect
      router.push('/login');
      setUser(null);
    });
  };

  // Simplified reload - uses Amplify for all cases
  const reload = async () => {
    setLoading(true); // Indicate loading during reload
    Auth.currentAuthenticatedUser()
      .then((user) => setUserWrap(user))
      .catch(() => setUserWrap(null))
      .finally(() => setLoading(false)); // Use finally to ensure loading is set to false
  }

  const values = useMemo(() => ({ user, login, logout, loading, reload }), [
    user,
    loading,
  ]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthProvider` component'
    );
  }
  return context;
};

export const PageAuth = ({ enabled = false, children }) => {
  const { user, loading } = useAuth();
  const router = useRouter();
  useEffect(() => {
    if (enabled && !loading && !user) {
      router.push('/login');
    }
  }, [enabled, loading, user]);
  if (enabled && !user) {
    return (
      <>
        <PageLoading />
        <div className="invisible hidden">{children}</div>
      </>
    );
  }
  return children;
};
